export const PI = [
  { header: "Principal Investigator" },
  {
    name: "Dr. Mona Minkara",
    title: "Assistant Professor, Bioengineering",
    img: "mona",
    alt: "Mona is wearing a purple and tan headscarf and a black blazer.",
    bio: "Dr. Mona Minkara is an Assistant Professor in the Department of Bioengineering and an Affiliate Faculty in the Department of Chemistry and Chemical Biology at Northeastern University. She received her Bachelor of Arts from Wellesley College and her Ph.D. in Chemistry from the University of Florida. Dr. Minkara is the head of the Minkara COMBINE (Computational Modeling for Biointerface Engineering) Lab, which focuses on the study of pulmonary surfactant, a complex protein-lipid substance secreted by the alveoli in the lungs and its potential therapeutic applications in the medical field. Dr. Minkara specializes in using an array of computational tools to apply to biomolecular problems, and her laboratory is developing innovative approaches that combine protein and surfactant modeling to investigate the PS system computationally. As a blind scientist, she is committed to making science more accessible and inclusive through engineering new tools for blind and partially sighted scientists, and she is involved in several projects dedicated to promoting accessibility in STEM fields. The importance of interdisciplinary research is emphasized in her work, which highlights the efficacy of computational methods in drug discovery and elucidating the complexities of the pulmonary surfactant (PS) system.",
    education:"Northeastern University",
    funFact:
      "Mona completed her postdoc at the University of Minnesota and is also a blind scientist. She also has a plaque of her name at Wellesley College, her alma mater.",
  },
];

export const PhDStudents = [
  { header: "Doctoral Researcher" },
  {
    name: "Deng Li",
    title: "PhD Candidate, Bioengineering",
    education:"Northeastern University",
    img: "deng2",
    alt: "Deng is wearing a blue shirt on top of a black shirt.",
    linkedIn:"https://www.linkedin.com/in/deng-li-589068127/",
    awards: " • Bioengineering Conference Travel Award, Northeastern University, USA, 2023• Ph.D. Network Travel Funding, Northeastern University, USA, 2023 • ACCESS High-Performance Computing Credits, National Science Foundation (NSF), USA, 2022International Conference Travel Grant, Ministry of Science and Technology, Taiwan, 2019• Excellent Mainland Chinese Students Scholarship, Chung Yuan Christian University, Taiwan, 2015 - 2016• Presidential Award, Chung Yuan Christian University, Taiwan, 2014 - 2017 • Presidential Award, I-Shou University, Taiwan, 2013",
    program:"Immunoproteins",
    major:"",
    research:"The application of molecular dynamics simulations to explore the molecular mechanisms of the pulmonary surfactant system, particularly those of surfactant protein D (SP-D).",
    year: "2023",
    // bio: "Research Focus: Applying molecular dynamics method on surfactant protein D (SP-D).",
    bio: "Deng's research focuses on the application of molecular dynamics simulations to explore the molecular mechanisms of the pulmonary surfactant system, particularly those of surfactant protein D (SP-D). Deng investigated molecular mechanisms of cartilage tissue degradation in his master’s. He also has climbed one-third of “The One Hundred Peaks of Taiwan,” each of which has an altitude over 3000 meters.",
  },
  {
    
      name: "Md Rafid Hasan",
      title: "PhD Student, Bioengineering",
      education:"Northeastern University",
      img: "Md-Rafid",
      alt: "Rafid is wearing muslim cap and a grey kurta.",
      linkedIn: "https://www.linkedin.com/in/rafidhasan08/",
      program: "Training",
      major: "",
      research: "Training",
      bio: "Rafid computationally modelled peptide inhibitors and investigated their therapeutic potentiality against viral main protease in his bachelor’s. He also loves traveling and has traveled using both bicycle and motorbike."
  
  },
  {
    
    name: "Odame Agyapong",
    title: "PhD Student, Bioengineering",
    education:"Northeastern University",
    img: "Odame2",
    alt: "Odame is wearing white sweatshirt.",
    linkedIn: "https://www.linkedin.com/in/odameagyapong",
    program: "Training",
    major: "",
    research: "Training",
    bio: "Odame's research focuses on employing computational modelling techniques to provide deep insights into molecular interactions of pulmonary surfactant proteins, especially how they interact within pathogens like bacteria. He has previously leveraged computational approaches including docking, molecular dynamics simulations as well as machine learning models to identify promising drug candidates from large chemical libraries. Apart from his research work, he enjoys music and soccer."

}
];

export const mastersStudents = [{ header: "Master's Students" }];

export const postBaccalaureate = [
  { header: "Post Baccalaureate" },
  {
    name: "Jack Tietjen",
    title: "Post-Baccalaureate Research Student",
    education: "Northeastern University",
    img: "jack2",
    major:"",
    linkedIn:"https://www.linkedin.com/in/jack-tietjen-57b988139/",
    program:"Alveolar Interface",
    research:"Utilization of computational methods to analyze the structure and function of pulmonary surfactant, aiming at improving the understanding of clinical pathologies such as Acute Respiratory Distress Syndrome (ARDS). Collaborates with the Program for Advancing Critical Care Translational Science (PACCTS) and the ILD Collaborative to advance knowledge of the pulmonary system from molecular mechanisms to therapeutic applications in intensive care units.",
    alt: "Jack is wearing a lightly blue checkered white shirt and a blue tie.",
    bio: "Jack's research focuses on the utilization of computational methods to analyze the structure and function of pulmonary surfactant, aiming at improving the understanding of clinical pathologies such as Acute Respiratory Distress Syndrome (ARDS). He collaborates with the Program for Advancing Critical Care Translational Science (PACCTS) and the ILD Collaborative to advance knowledge of the pulmonary system from molecular mechanisms to therapeutic applications in intensive care units.Jack is deeply involved in advancing the field of pulmonary care, contributing to research on the molecular to clinical aspects of the respiratory system. Following a post-baccalaureate program at Northeastern University in 2024, he intends to apply to medical school. Outside of his professional interests, Jack enjoys outdoor activities, recording music, and spending time with his cat, Beeps.",
  },
];

export const undergraduateStudents = [
  { header: "Undergraduate Researcher" },
  {
    name: "Milo Batista",
    title: "",
    img: "milo",
    education: "Northeastern University",
    major: "Bioengineering, Biochemistry",
    linkedIn:"https://www.linkedin.com/in/milo-batista-9942a51ab/",
    research: "Training",
    alt: "Milo is wearing a gray t-shirt.",
    bio: "Milo is currently focusing on his training within the COMBINE Lab. Born in Manhattan and raised in a serene mountain village north of NYC, Milo brings a unique background to his studies. Beyond the academic and professional realms, Milo is passionate about music, showcasing talent in playing the guitar, practicing archery for discipline and focus, and engaging in video games for relaxation and entertainment.",
  },
  {
    name: "Amanda Ferrante",
    title: "",
    img: "amanda",
    education: "Northeastern University",
    major: "Bioengineering, Biochemistry",
    linkedIn: "https://www.linkedin.com/in/am-ferrante/",
    program: "Alveolar Interface",
    research: "Amanda’s research project, 'Atomistic Investigations of the Pulmonary Surfactant (PS) System through Examining SP-C and Cholesterol Interactions for Advancements in Respiratory Health' aims to unravel the intricate interactions between SP-C and cholesterol is critical for understanding respiratory health.",
    alt: "Amanda is wearing a black shirt.",
    bio: "Amanda’s research project, 'Atomistic Investigations of the Pulmonary Surfactant (PS) System through Examining SP-C and Cholesterol Interactions for Advancements in Respiratory Health' aims to unravel the intricate interactions between SP-C and cholesterol, which is critical for understanding respiratory health.Originally from Long Island, New York, Amanda is passionate about merging the fields of bioengineering, biochemistry, and medicine. Amanda enjoys spending time with friends and exploring Boston, eagerly anticipating the experiences and opportunities that await her in the vibrant city and beyond.",
  },
  {
    name: "Paloma Figueroa",
    title: "",
    img: "paloma2",
    education: "Northeastern University",
    alt: "Paloma is wearing a brown turtle neck.",
    linkedIn:"https://www.linkedin.com/in/paloma-figueroa/",
    awards:"• Srinivasan Award for Projects in Emerging Markets • Richard J. Scranton Fund Organization Grant • National Science Foundation Research Experience for Undergraduates (REU) • PEAK Ascent Award",
    program: "Immunoproteins",
    major: "Bioengineering",
    research:"Deciphering ManLAM Interactions: Computational Insight into MBL Binding to Tuberculosis ",
    bio: "Paloma’s research focuses on 'Deciphering ManLAM Interactions: Computational Insight into MBL Binding to Tuberculosis'. Paloma is from Dallas, Texas but originally born in San Juan, Puerto Rico. She has been a part of the COMBINE Lab since Fall 2021. Outside of the lab, she enjoys music, traveling to new places, and going to the gym.",
    // bio: "Hi! My name is Paloma and I am a second-year bioengineering student with a concentration in Medical Devices and Bioimaging. I am from Dallas, Texas but originally born in San Juan, Puerto Rico. I have been involved in the COMBINE Lab since September 2021. My research focuses on analyzing the relationship between Mannose Binding Lectin and Tuberculosis using Molecular Dynamics. Outside of the lab, I enjoy music, traveling to new places, and going to the gym!",
  },



  // {
  //   name: "Luisa Li",
  //   title: "",
  //   img: "luisa",
  //   education: "Northeastern University",
  //   major: "Computer Science, Mathematics",
  //   linkedIn:"https://www.linkedin.com/in/luisa-li-t/",
  //   research:"Exploring the intersection of computational work and biology, particularly within the realm of drug discovery.",
  //   alt: "Luisa is wearing a black sweater.",
  //   bio: "Luisa's research explores the intersection of computational work and biology, particularly within the realm of drug discovery.At a Cambridge-based biotech co-op, Luisa engages with molecular data to deepen her understanding of the scientific processes underlying drug development. ",
  // },

 {
  name: "Stephanie Liu",
  title: "",
  img: "stephanie-liu",
  education: "Northeastern University",
  alt: "Stephanie is wearing a brown sweater and smiling.",
  linkedIn: "https://www.linkedin.com/in/stephanie-liu-northeastern",
  awards: "• Northeastern National Merit Scholar",
  program: "Training",
  major: "Bioengineering",
  research: "Monte Carlo and Molecular Dynamics",
  bio: "Stephanie is a bioengineering major with a minor in mathematics and a concentration in systems, synthetic, and computational bioengineering. Outside of the lab, she enjoys music, spending time with her cats, and going to concerts."
},
{
  name: "Aayati Patel",
  title: "",
  img: "aayati",
  education: "Northeastern University",
  major: "Bioengineering",
  research:"Training",
  alt: "Aayati is smiling in a grey sweater and black tshirt.",
  bio: "Aayati is a second-year Bioengineering major interested in exploring the field of computational bioengineering. Apart from academics, she enjoys traveling, trying new foods, reading fantasy books, and taking long walks.",
},
  {
    name: "Amit Shenoy",
    title: "",
    img: "amit",
    education: "Northeastern University",
    linkedIn:"http://www.linkedin.com/in/itsamit",
    major: "Bioengineering",
    program: "Immunoproteins",
    awards:"• Lead360 Emerging Leader Award • National Piano Guild Award • Dean's List, Honors Program",
    research:"Investigating how Mannose-Binding-Lectin (MBL), a human first-line-of-defense protein binds to various viral pathogens. The results, to be measured in binding affinity, hydrogen bond fraction, structural changes, etc. could help future host-pathogen research, aid in biomarker development, and provide leads for therapeutic drug discovery.",
    alt: "Amit is wearing a blue striped tie and light blue collared shirt.",
    bio: "Amit's research investigates how Mannose-Binding-Lectin (MBL), a human first-line-of-defense protein, binds to various viral pathogens. The results, to be measured in binding affinity, hydrogen bond fraction, structural changes, etc., could help future host-pathogen research, aid in biomarker development, and provide leads for therapeutic drug discovery.Amit enjoys a good mystery and solving challenges alongside a team. His interests include strategy games, social justice activism, and bioengineering.",
  },
  {
    name: "Ann Titus",
    title: "",
    img: "ann2",
    education: "Northeastern University",
    linkedIn:"www.linkedin.com/in/ann-titus-049111271",
    major: "Bioengineering",
    program: "Alveolar Interface",
    awards:"• Base Camp Award Winner • Ascent Award Winner",
    research:"Using Monte Carlo methods based in Python to model the coexistence curve of Pentadecanoic Acid, a compound used to computationally model the monolayer at the liquid-vapor interface of the lungs.",
    alt: "Ann is wearing a white shirt.",
    bio: "Ann's research uses Monte Carlo methods based in Python to model the coexistence curve of Pentadecanoic Acid, a compound used to computationally model the monolayer at the liquid-vapor interface of the lungs.Ann Titus is a bioengineering major concentrating in cell and tissue engineering. Outside of school, she is a dancer on Northeastern’s Bhangra Team, a Residential Assistant, plays the violin, and loves traveling.",
  },
  
  //{
  //  name: "Shria Wadhwa",
  //  title: "",
  //  img: "shria2",
  //  education: "Northeastern University",
  //  major: "Bioengineering",
  //  linkedIn:"https://www.linkedin.com/in/shria-wadhwa-316601211/",
  //  research: "Training",
  //  program: "",
  //  alt: "Shria is wearing a blue and white patterned shirt in front of a body of water.",
  //  bio: "Originally from Chicago, IL, Shria enjoys exploring new restaurants, spending quality time with friends and family, and playing with her dog.",
  //},


];


export const accessAssistants = [
  { header: "Access Assistants" },
  {
    name: "Dr. Elizabeth Andrews",
    title: "Scientific Access Writer, COMBINE Lab",
    education: "Northeastern University",
    img: "elizabeth2",
    alt:"Elizabeth wears a green top ",
    work: "Dr. Andrews’ work navigates the intersection of scientific communication and arts-based inquiry to foster collaborative writing practices and craft accessible scientific narratives. Drawing on her background as an interdisciplinary poet and scholar, her work has focused on dismantling barriers to participation within the academic community.",
    awards: "• Outstanding Arts Based Research Award from the American Educational Research Association • Penn State University’s Distinguished Graduate Fellowship • Academy of American Poets Albert J. Kaplan Prize",
    bio: "Dr. Elizabeth Andrews works as a Scientific Access Writer at Northeastern University, where she helps make scientific research accessible. With a background in both science and the arts, Elizabeth is dedicated to breaking down barriers and creating new methods that support everyone in conducting literature-based research, writing, and publishing within the academic community. ",
    // bio2: "Elizabeth is a certified wellness coach and herbalist currently working to restore and farm her family’s third-generation homestead, Silver Birch Farm, in New York State. Dr. Janney’s work won the Outstanding Arts Based Research Award from the American Educational Research Association. ",
    linkedIn: "https://www.linkedin.com/in/elizabethjanney ",
    website:"https://www.elizabeth-andrews.com ",
  },
  {
    name: "Priyadarsini Seenivasakap Perumal",
    title: "Research Access Coordinator for the COMBINE Lab",
    education: "Northeastern University",
    img: "priya",
    alt:"Priya wears a yellow blouse.",
    linkedIn: "https://www.linkedin.com/in/priyadarsini-s/",
    website:"",
    work: "Priya’s primary responsibility is to provide scientific access by making research articles related to pulmonary surfactant accessible and describing visualizations of molecular dynamics. She ensures that the scientific material is easily accessible to Dr. Minkara. She also supports Professor Minkara’s lecture classes and works closely with the COMBINE Lab research group. ",
    bio: "Priya’s primary responsibility is to provide scientific access by making research articles related to pulmonary surfactant accessible and describing visualizations of molecular dynamics. She ensures that the scientific material is easily accessible to Dr. Minkara. She also supports Professor Minkara’s lecture classes and works closely with the COMBINE Lab research group. Priya holds educational qualifications in Biotechnology and a diploma in culinary arts. Apart from her professional responsibilities, she finds joy in cooking, which is not just a hobby but also a therapeutic activity for her.",
  },
  {
    name: "Dr. Mike Weaver",
    title: "Literature Access Coordinator for the COMBINE Lab ",
    education: "Northeastern University",
    img: "mike",
    website:"",
    alt:"Mike wears sunglasses and a gray jacket.",
    work: "Dr. Weaver is instrumental in making scientific research accessible and supporting student research in the COMBINE Lab. He is committed to mentorship and the democratization of scientific knowledge. He prepares references, assists in preparing papers for publication, and creates an accessible 'Paper of the Day' to highlight significant scientific work. ",
    bio: "Dr. Weaver is instrumental in making scientific research accessible and supporting student research in the COMBINE Lab. He is committed to mentorship and the democratization of scientific knowledge. He prepares references, assists in preparing papers for publication, and creates an accessible 'Paper of the Day' to highlight significant scientific work.Dr. Mike Weaver's academic journey led him from a postdoctoral position in Professor Kennie Merz's research group at the University of Florida to his current role as a Literature Access Coordinator at Northeastern University. Originally mentoring Dr. Minkara as a blind graduate student, his story is reminiscent of a Star Wars-esque mentor-apprentice reunion, where roles reversed when he joined the COMBINE Lab team.",
    linkedIn: "",
  },
 
];

export const alumni = [
  { header: "Alumni" },
  {
    name: "Jake Duffy",
    title: "",
    img: "jake",
    alt: "Jake is wearing a white shirt and glasses.",
    awards: "Alveolar InterfaceG7	Peak award summer 2020",
    bio: "My name is Jake, and I’m a computer science and physics student. I grew up about 45 minutes north of Chicago. I’m working on Monte Carlo simulations of pentadecanoic acid (PDA), and I’m excited to be conducting research in computational chemistry as it both applies my computer science curriculum as well as extends my knowledge to a new field.",
  },
  {
    name: "Liam Farley",
    title: "",
    img: "liam",
    alt: "Liam is wearing a navy blue collar shirt with white polka dots.",
    awards: "",
    bio: "My name is Liam, I am a senior year chemistry student at Reed College. I am Brazilian-American who loves snowboarding, hiking, and the ocean. I am excited to be expanding my knowledge into the field of biology and developing my computational research skills. Currently, I am using molecular docking and molecular dynamics simulations to study SARS-CoV-2 proteins and identify possible drug leads.",
  },
  {
    name: "Tyler Locke",
    // position: "- PhD Biomedical Engineering Student - Cornell University",
    title: "",
    img: "tyler",
    alt: "Tyler is wearing a bow tie on a checkered shirt with a gray blazer.",
    awards: "Peak Award summer 2021",
    bio: "Tyler was an undergraduate researcher in the COMBINE lab from 2020-2023. During that time, he focused on understanding the lipid-modulating function of surfactant Protein B using comparative modeling and molecular dynamics simulation. He is currently pursuing a phD in Biomedical engineering at Cornell Univeristy, working with novel optical microscopy techniques to study heart disease.",
  },
  {
    name: "Aastha Mahapatra",
    title: "",
    img: "aastha",
    alt: "Aastha is wearing a red embroidered shirt and glasses.",
    awards: "Northeastern Bioengineering Research Symposium Award",
    bio: "Hello! my name is Aastha, and I am pursuing master’s in regulatory affairs at the college of professional studies. I recently joined the Dr. Minkara’s lab as a research assistant. My research mainly focuses on applying the molecular docking analysis technique on pulmonary surfactant proteins to establish a distinction between the binding patterns of these proteins with different molecules. This research will give me a base to expand further and use molecular dynamics to study the molecular level mechanisms of the pulmonary surfactant system, mainly that of pulmonary surfactant A (SP-A). I have completed my bachelor’s degree in pharmacy my concentration being computer aided drug designing from Siksha O Anusandhan University, Bhubaneswar, India. I also like playing basketball and travelling to different places.",
  },
  {
    name: "Carolina Escobar Palacio",
    title: "",
    img: "carolina",
    alt: "Carolina is wearing a red and purple striped jacket overlooking a body of water.",
    awards: "",
    bio: "Hello! My name is Carolina and I’m a third-year bioinformatics student. I finished high school in 2013 in Medellin, Colombia (where I was also born) and I’ve been living in the U.S. for 6 years now. In the combine lab, I’m using molecular dynamics simulations to study SARS-CoV-2 proteins. I’m very excited about my project as I will be using different concepts of bioinformatics and apply them to such a relevant topic! My long-term goal is to pursue a PhD and do research related to computational chemistry.",
  },
  {
    name: "Rohit Paradkar",
    title: "",
    img: "rohit",
    alt: "Rohit is wearing a dark hoodie.",
    awards: "",
    bio: "Rohit Paradkar is a sophomore at Newton South High School. He’s originally from Colorado Springs, Colorado, and loves to ski and spend time outdoors. He’s very passionate about chemistry, biology, and computer science. He also loves to play tennis and watch football. His work in COMBINE lab deals with molecular docking for SARS-CoV-2 drug discovery.",
  },
  
  {
    name: "Lydia Ruffner",
    title: "",
    img: "lydia",
    alt: "Lydia is wearing a black collar shirt and black cardigan.",
    bio: "Lydia Ruffner is a Postdoctoral Research Associate in the Department of Bioengineering. Her research in the COMBINE lab focuses on exploring the function of proteins in the pulmonary surfactant system such as surfactant protein C (SP-C). She recently defended her PhD from the Department of Chemistry and Chemical Biology at Northeastern University in early 2021. Her dissertation work used enzyme structure to determine the global and local structural differences in dynamics that may lead to the functional characterization of important disease related enzymes in uncharacterized metabolic pathways for pharmaceutical application. She is an alumna of Spelman College (C’14) where she majored in Biochemistry with a minor in Mathematics. Born in a DMV (DC, Maryland and Virginia) native who loves to traveling and enjoys spending times with family and friends.",
  },
 
  {
    name: "Catherine Zhou",
    title: "",
    img: "catherine",
    alt: "Catherine is wearing a gray sweater vest and white collar shirt.",
    awards: "",
    bio: "Hi! I’m Catherine, and I’m a first-year Chemical Engineering and Biochemistry major pursuing the pre-med track. I’m from the suburbs north of Dallas, TX. I will soon be working on researching the vapor liquid coexistence of pentadecanoic acid and palmitic acid using Monte Carlo Simulations. I am excited to apply my chemical engineering knowledge to real-world research, and to further sharpen my programming skills along the way.",
  },

 
];
