import React from 'react';
import { Row, Col, Image } from "../../bootstrap.js";
import { newsContent } from "./newsContent.js";
import "./news.css";
import { useMediaQuery } from "usehooks-ts";

export function News({ news = newsContent }) {
  const isMobile = useMediaQuery("(max-width: 992px)");

  // Function to handle PDF link clicks
  const handlePDFClick = (url, event) => {
    event.preventDefault(); // Prevent the default anchor behavior
    if (url.endsWith('.pdf')) {
      window.open(url, '_blank'); // Opens the PDF in a new tab
    } else {
      window.location.href = url; // Handle other URLs normally
    }
  };

  return (
    <>
      <div className="newsBG">
        <header>
          <h3 className="newsHeader">News</h3>
        </header>
        <Row className="featuredNewsRow">
          <Col xs={9} sm={7} md={6} lg={4} xl={3} xxl={3}>
            <Image src="/images/Group/NSF2.jpg" className="img-fluid nsfCareer" alt="NSF career award logo" />
          </Col>
          <Col xs={12} sm={12} md={6} lg={8} xl={9} xxl={9}>
            <a href={news[0].url} onClick={(e) => handlePDFClick(news[0].url, e)} className="newsLink">
              <header>
                <h3 className="featuredNewsTitle">{news[0].title}</h3>
                <p className="featuredNewsDate">{news[0].date}, <span className="featuredNews">Featured</span></p>
              </header>
              <p className="summary">{news[0].summary}</p>
            </a>
          </Col>
        </Row>
      </div>

      <section className="news">
        {news.map((item, index) => (
          <Row key={item.id} className={index === 0 ? "newsRow0" : "newsRow"}>
            <Col className="m-0 p-0 newsCol">
              <a
                href={item.url}
                onClick={(e) => handlePDFClick(item.url, e)}
                className="newsLink"
              >
                <p className="newsDate">{item.date}</p>
                <header>
                  <h3 className="newsTitle">{item.title}</h3>
                </header>
                <p className="summary">
            {item.summary.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
            </p>
              </a>
            </Col>
            <div className="imgCont">
            <Image
                src={`/images/Group/${item.img}.jpg`}
                onError={(e) => (e.target.src = `/images/Group/${item.img}.jpeg`)} 
                className={item.img === "NSF2" ? "nsfCareer2" : "newsImg"} 
                alt={item.alt}
              />

            </div>
          </Row>
        ))}
      </section>
    </>
  );
}
