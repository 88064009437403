import React, { useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import emailjs from '@emailjs/browser';
import { SubmitButton } from "../SubmitButton/SubmitButton.js";
import "./apply.css";

export function Apply() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      e.preventDefault();
      const { name, email, education, field, interest, skills, research, position } = e.target.elements;

      const templateParams = {
        from_name: name.value,
        from_email: email.value,
        from_education: education.value,
        from_field: field.value,
        from_interest: interest.value,
        from_skills: skills.value,
        from_research: research.value,
        from_position: position.value,
      };

      await emailjs
        .send(
          "service_qv3fq1a", // Replace with your EmailJS service ID
          "template_d09tq3t", // Replace with your EmailJS template ID
          templateParams,
          "YkS6o_EoSAdsKQX2g" // Replace with your EmailJS user ID
        )
        .then((response) => {
          console.log("Email sent successfully!", response);
          alert("Thank you for applying to the Minkara COMBINE Lab!");

          // Optionally, you can reset the form here
          e.target.reset();
        })
        .catch((error) => {
          console.error("Error sending email:", error);
        });
    }
    setValidated(true);
  };

  return (
    <section className="applyPadding">
      <header>
        <h2 className="apply">
          Join the Minkara <span className="combine2">COMBINE</span> Lab!
        </h2>
      </header>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="rowApply">
          <Col xs={6} md={6} className="applyCont">
            <Form.Group controlId="name" className="mb-3">
              <Form.Label className="formLabel">Full Name</Form.Label>
              <Form.Control type="text" name="name" required className="box3D" />
              <Form.Control.Feedback type="invalid">
                Full Name is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="email" className="mb-3">
              <Form.Label className="formLabel">Email</Form.Label>
              <Form.Control type="email" name="email" required className="box3D" />
              <Form.Control.Feedback type="invalid">
                Email is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="education" className="mb-3">
              <Form.Label className="formLabel">Current Education Level</Form.Label>
              <Form.Control type="text" name="education" required className="box3D" />
              <Form.Control.Feedback type="invalid">
                Current Education Level is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="field" className="mb-3">
              <Form.Label className="formLabel">Field of Study</Form.Label>
              <Form.Control type="text" name="field" required className="box3D" />
              <Form.Control.Feedback type="invalid">
                Field of Study is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="interest" className="mb-3">
              <Form.Label className="formLabel">Why You Are Interested in Applying</Form.Label>
              <Form.Control as="textarea" name="interest" required className="box3D" rows={5} />
              <Form.Control.Feedback type="invalid">
                This field is required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="skills" className="mb-3">
              <Form.Label className="formLabel">Applicable Skills</Form.Label>
              <Form.Control type="text" name="skills" required className="box3D" />
              <Form.Control.Feedback type="invalid">
                Applicable Skills are required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="research" className="mb-3">
              <Form.Label className="formLabel">Research Interests</Form.Label>
              <Form.Control type="text" name="research" required className="box3D" />
              <Form.Control.Feedback type="invalid">
                Research Interests are required.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="position" className="mb-3">
              <Form.Label className="formLabel">Position of Interest</Form.Label>
              <Form.Control type="text" name="position" required className="box3D" />
              <Form.Control.Feedback type="invalid">
                Position of Interest is required.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row className="bottomContact gy-2">
          <SubmitButton />
        </Row>
      </Form>
    </section>
  );
}
